<template>
  <div class="headerbox">

    <div class="header">
      <div class="headerbox">
        <div class="headerlogo">
          <img class="logoimage" src="@/assets/images/logo.png" alt="">
        </div>
        <div class="nav">
          <div v-for="item in sitelist" :key="item.id"  @click="changeCity(item)"
            :class="{ 'navitem': true, 'currentnav': item.current }">{{
              item.name
            }}</div>
        </div>
        <div class="rightnav">
          <div class="bigdatabtn btnbox" @click="toBigdata">
            <img class="btnimg" src="@/assets/images/tpdsj.png" alt="">
            <span>土拍大数据</span>
          </div>
          <div class="jiameng btnbox" @click="toJiameng">
            <img class="btnimg" src="@/assets/images/hzjm.png" alt="">
            <span>合作加盟</span>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import { sitelist } from '@/api/website/site.js'

export default {
  name: 'PcWebsiteSiteHeader',

  data() {
    return {
      sitelist: [],
      currentSiteRoute: '',
      currentSiteId: null
    };
  },

  mounted() {


    this.getSite()
  },
  watch: {
    $route: {
      handler(route) {


        const path = route.params.city
        //存储当前站点信息
        this.currentSiteRoute = path; //当前站点的路由字符串参数

        this.sitelist.map(site => {
          if (site.path === path) {

            this.currentSiteId = site.id //当前站点的id
            //将站点id存储到vuex 和 sessionStoreage中
            this.$store.dispatch('site', site);
          }
        })

        this.handleSiteList(this.sitelist) // 更改当前站点样式

      },
      deep: true
    }
  },
  methods: {
    //土拍大数据
    toBigdata() {
      const url = process.env.VUE_APP_LOGIN_INDEX_DOMAIN
      // alert(url)
      // this.$router.push(url)
      window.open(url)
      // window.location.href = url
    },

    //加盟
    toJiameng() {
      this.$router.push('/cn/jointupai')
    },

    //改变城市
    changeCity(city) {
      if (process.env.NODE_ENV === 'production') {
        if (city.domain != null) {
          window.location.href = city.domain
        } else {
          if (this.$route.fullPath !== '/' + city.path + '/index') {
            this.$router.push('/' + city.path + '/index')
          }
        }
      } else {
        if (this.$route.fullPath !== '/' + city.path + '/index') {
          this.$router.push('/' + city.path + '/index')
        }
      }
    },


    getSite() {

      sitelist().then(res => {
        const sitelist = res.data
        console.log('sitelist', sitelist)
        this.sitelist = this.handleSiteList(sitelist)
        const path = this.$route.params.city

        this.sitelist.map(site => {
          if (site.path === path) {
            this.currentSiteId = site.id //当前站点的id
            this.$store.dispatch('site', site)
          }
        })
      })

    },

    //处理站点列表增加 current 选中
    handleSiteList(sitelist) {
      //当前站点
      const currentSite = this.$route.params.city
      const handleSiteList = sitelist.map(item => {
        if (item.path === currentSite) {
          item.current = true
        } else {
          item.current = false
        }
        return item
      })
      return handleSiteList
    }



  },
};
</script>

<style lang="scss" scoped>
.btnbox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .btnimg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}

.headerbox {
  height: 74px;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 74px;
  background: #fff;
  position: fixed;
  margin-bottom: 74px;
  z-index: 100;

  .headerbox {
    max-width: 1462px;
    min-width: 1402px;
    height: 100%;
    background: #fff;
    height: 74px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .headerlogo {
      width: 162px;
      height: 62px;

      .logoimage {
        margin-left: 22px;
        width: 140px;
        height: 62px;
      }
    }

    .nav {
      flex: 1;
      display: flex;
      margin-left: 47px;

      .navitem {
        width: 84px;
        height: 74px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 74px;
        cursor: pointer;
        color:#000;
      }

      .currentnav {
        background: rgba(0, 163, 228, 0.1);
        color: #00A3E4;
        border-bottom: solid 4px #00A3E4;
        box-sizing: border-box;
      }

    }


    .rightnav {
      display: flex;
      width: 266px;
      height: 48px;

      .bigdatabtn {
        width: 134px;
        height: 48px;
        line-height: 48px;
        background: #00A3E4;
        border-radius: 2px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }

      .jiameng {
        width: 118px;
        height: 48px;
        line-height: 48px;
        background: #E77817;
        border-radius: 2px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 14px;
      }


    }


  }

}
</style>